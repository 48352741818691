var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"speciality-area"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',{staticClass:"left-side-container mr-2",staticStyle:{"width":"200px"}},[_vm._m(0),_c('div',{staticClass:"shadow-effect mb-5"},[_c('h6',[_vm._v("Downloads :")]),_c('p',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getManuScript(
              'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/2/manuscripttemplate/IJRTMR-Manuscript-Template.docx'
            )}}},[_vm._v(" Manuscript Template ")]),_c('p',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getCopyRightForm(
              'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/2/copyrightform/IJRTMR_Registration+%26Copyright-Form.pdf'
            )}}},[_vm._v(" Copyright Form ")])]),_vm._m(1),_vm._m(2),_c('div',[_c('IndexingSideDesign')],1)]),_c('div',{staticClass:"container",staticStyle:{"max-width":"1000px !important","margin":"0"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 mt-2"},[(!_vm.listVisible && !_vm.paperPage)?_c('div',{staticClass:"archive-data-container"},_vm._l((_vm.years),function(year){return _c('div',{key:year},[_c('h6',[_vm._v(_vm._s(year))]),_vm._l((_vm.archives[year]),function(data,index){return _c('div',{key:data.index,staticClass:"row"},_vm._l((data.slice().sort()),function(issue){return _c('div',{key:issue.index,staticClass:"col-lg-3 databox m-1 issueButton",on:{"click":function($event){return _vm.viewIssueData(year, index, issue)}}},[_c('a',{attrs:{"href":'archives/paperlist?year=' +
                          year +
                          '&volume=' +
                          index +
                          '&issue=' +
                          issue}},[_c('p',[_vm._v("Volume "+_vm._s(index)+" Issue "+_vm._s(issue))])])])}),0)})],2)}),0):_vm._e()]),(_vm.listVisible)?_c('PaperListComponent',{attrs:{"papersList":_vm.papersList,"cYear":_vm.cYear,"cVolume":_vm.cVolume,"cIssue":_vm.cIssue,"listVisible":_vm.listVisible,"paperPage":_vm.paperPage},on:{"view-paper-details":_vm.handleViewPaperDetails}}):_vm._e(),(_vm.paperPage)?_c('PaperDetailsComponent',{attrs:{"paperDetails":_vm.paperDetails,"paperPage":_vm.paperPage}}):_vm._e()],1)])]),_c('div',{staticClass:"right-side-container ml-2",staticStyle:{"width":"200px"}},[_c('div',{staticClass:"mb-5"},[_c('router-link',{attrs:{"to":"/editorial-board"}},[_c('p',{staticClass:"blink"},[_vm._v("Join As A reviewer")])])],1),_vm._m(3),_vm._m(4),_c('div',{staticClass:"shadow-effect mb-5"},[_c('h4',[_vm._v("For Authors")]),_c('router-link',{attrs:{"to":"topics"}},[_c('p',[_vm._v("Topics")])]),_c('router-link',{attrs:{"to":"call-for-papers"}},[_c('p',[_vm._v("Call For Papers")])]),_c('router-link',{attrs:{"to":"instruction-for-author"}},[_c('p',[_vm._v("Instruction For Authors")])]),_vm._m(5),_c('router-link',{attrs:{"to":"guidance-for-ems"}},[_c('p',[_vm._v("Guidance For EMS")])]),_c('router-link',{attrs:{"to":"article-processing-charges"}},[_c('p',[_vm._v("Article Processing Charges")])]),_c('router-link',{attrs:{"to":"downloads"}},[_c('p',[_vm._v("Downloads")])]),_c('router-link',{attrs:{"to":"paper-status"}},[_c('p',[_vm._v("Paper Status")])])],1),_c('div',{staticClass:"shadow-effect"},[_c('h4',[_vm._v("Policies")]),_c('router-link',{attrs:{"to":"ethics"}},[_c('p',[_vm._v("Ethics And Policies")])]),_c('router-link',{attrs:{"to":"peer-review-policy"}},[_c('p',[_vm._v("Peer Review Policy")])]),_c('router-link',{attrs:{"to":"publication-ethics-policy"}},[_c('p',[_vm._v("Publication Ethics Policy")])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"blink mb-5"},[_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"http://editorial.fdrpjournals.org/login?journal=3"}},[_vm._v("Submit Research Paper")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow-effect mb-5"},[_c('img',{attrs:{"alt":"image","src":require("../../../assets/Images/isn.jpg"),"height":"90","width":"200"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow-effect mb-5"},[_c('img',{attrs:{"alt":"image","src":require("../../../assets/Images/cc.jpg"),"height":"90","width":"180"}}),_c('p',[_vm._v(" All research Article published on this website are licensed under Creative Commons Attribution-ShareAlike 4.0 International License, and all rights belong to their respective authors/researchers. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow-effect mb-5"},[_c('img',{attrs:{"alt":"image","src":require("../../../assets/Images/tur.jpg"),"height":"90","width":"180"}}),_c('p',[_vm._v("Plagiarism is checked by the leading plagiarism checker")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow-effect mb-5"},[_c('img',{attrs:{"alt":"image","src":require("../../../assets/Images/doi.jpg"),"height":"90","width":"180"}}),_c('p',[_vm._v(" CrossRef DOI is assigned to research Article published in our journal. IJIRE DOI prefix is10.59256/ijire ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{staticStyle:{"color":"rgb(100, 117, 137)"},attrs:{"href":"http://editorial.fdrpjournals.org/login?journal=3"}},[_vm._v("Manuscript Submission")])])}]

export { render, staticRenderFns }